import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'app/service/http/http.service';

@Component({
  selector: 'app-balance-history-modal',
  templateUrl: './balance-history-modal.component.html',
  styleUrls: ['./balance-history-modal.component.scss']
})
export class BalanceHistoryModalComponent implements OnInit {
  
  @Input() show: boolean = false;
  @Input() data: any;
  @Input() availableBalance: any;
  @Output() closeModal = new EventEmitter<any>();
  detailsUrl: string = "http://localhost:4200/orders/destination-status/";

  constructor(
    private _http: HttpService
  ) { 
    
  }

  ngOnInit(){
    console.log('available: ', this.availableBalance);
  }

  onClose(): void {
    this.show = false;
    this.closeModal.emit(this.show);
  }

  showModal(): void {
    console.log('Mostrando data: ', this.data);
    setTimeout(() => {
      this.show = true;
    }, 0);
  }

}
