import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subscription,interval } from 'rxjs';
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import PerfectScrollbar from 'perfect-scrollbar';
import { TourService,IStepOption } from 'ngx-tour-ng-bootstrap';
import { filter } from 'rxjs/operators';

import { MenuItems } from '../core/menu/menu-items/menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';
import { AuthService } from '../service/auth/auth.service';
import { CoreService } from '../service/core/core-service.service';
import { StorageService } from 'app/core/services/storage.service';
import swal from'sweetalert2';
import { HttpService } from 'app/service/http/http.service';
declare var  require;

const screenfull = require('screenfull');

@Component({
	selector: 'chankya-layout',
	templateUrl: './main-material.html',
	styleUrls: ['./main-material.scss'],
	encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnInit, OnDestroy {

	dark                   : boolean;
	boxed                  : boolean;
	collapseSidebar        : boolean;
	compactSidebar         : boolean;
	customizerIn           : boolean = false;
	chatWindowOpen         : boolean = false;
	chatSidebar            : boolean = false;
	sidebarClosed          : boolean = false;
	chatpanelOpen          : boolean = false;
	sidenavOpen            : boolean = true;
	isMobile               : boolean = false;
	isFullscreen           : boolean = false;
	_opened                : boolean = true;
	_showBackdrop          : boolean = false;
	_closeOnClickOutside   : boolean = false;
	showSettings           : boolean = false;

	_mode               : string = "push";
	sidenavMode         : string = 'side';
	themeSkinColor      : any = "light";
	headerSkinColor     : any = "light";
	root                : any = 'ltr';
	layout              : any = 'ltr';
	header              : string;
	url                 : string;
	public innerWidth   : any;
	input               : any;
	miniSidebar			  : boolean = true;
	private _router                     : Subscription;
	private _mediaSubscription          : Subscription;
	private _routerEventsSubscription   : Subscription;

	typeOrder = '';
	viewModuleCheck:number;
	viewIsAdmin:number;

	subModuleOrder = [];
	subModuleCheck = [];
	dataPermissionSub = [];

	userData: any;
	versionString = "v1.02";

	currentLang = 'es';
	id_user:number;

	@ViewChild('sidenav', {static: false}) sidenav;
  balance: any;
  balanceCantidad: any;
  //timerSubscription: Subscription;

	constructor(
		public tourService: TourService,
		private coreService: CoreService,
		private storageService: StorageService,
		public menuItems: MenuItems,
		private pageTitleService: PageTitleService,
		public translate: TranslateService,
		private router: Router,
		private media: MediaObserver,
		private _http: HttpService
	) {

		this.userData = this.storageService.getCurrentGrl();

		const browserLang: string = translate.getBrowserLang();
		translate.use(browserLang.match(/es|fr/) ? browserLang : 'es');

		/* this.tourService.events$.subscribe();
		this.tourService.initialize([
			{
				anchorId: 'start.tour',
				content: 'Welcome to the Chankya tour!',
				placement: 'below',
				title: 'Welcome to Chankya',
			},
			{
				anchorId: 'tour-search',
				content: 'Enjoying Search box with sugestion and many more things',
				placement: 'below',
				title: 'Search Box',
			},
			{
				anchorId: 'tour-full-screen',
				content: 'By pressing this button you can switch to fullscreen mode.',
				placement: 'below',
				title: 'Full Screen',
			},
			{
				anchorId: 'tour-ui',
				content: 'Create your frontend with beautifully designed UI-Panel. Pre integrated page templates and widgets provides you the power to create any design.',
				placement: 'right',
				title: 'Awesome UI-Panel',
			}]
		); */

		if (window.innerWidth > 1280) {
			this.tourService.start();
		}
	}

	ngOnInit() {
		this.getUser();
	  this.getValidateDhl();
    this.getBalance();
    /*interval(30000).subscribe(() => {
      this.getBalance();
    });*/

		this.innerWidth = window.innerWidth;
		this.pageTitleService.title.subscribe((val: string) => {
			this.header = val;
		});

		this._router = this.router.events.pipe(
			filter((event: Event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
				this.url = event.url;
			});

		if (this.url != '/session/login' && this.url != '/session/register' && this.url != '/session/forgot-password' && this.url != '/session/lockscreen') {
			const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container');

			/** Perfect scrollbar for sidebar menu **/
			if (window.matchMedia(`(min-width: 1280px)`).matches) {
				const ps = new PerfectScrollbar(elemSidebar, {
					wheelSpeed: 2,
					wheelPropagation: true,
					minScrollbarLength: 20
				});
				ps.update();
			}

			/** Perfect scrollbar for chat window **/
			const elemChatbar = <HTMLElement>document.querySelector('.chat-inner ');
			if (window.matchMedia(`(min-width: 1280px)`).matches) {
				const pse = new PerfectScrollbar(elemChatbar);
			}
		}

		if (this.media.isActive('xs') || this.media.isActive('sm') || this.media.isActive('md')) {
			this._mode = 'over';
			this._closeOnClickOutside = true;
			this._showBackdrop = true;
			this._opened = false;
			this.sidebarClosed = false;
		}

		this._mediaSubscription = this.media.media$.subscribe((change: MediaChange) => {
			let isMobile = (change.mqAlias == 'xs') || (change.mqAlias == 'sm')  || (change.mqAlias == 'md');

			this.isMobile = isMobile;
			this._mode = (isMobile) ? 'over' : 'push';
			this._closeOnClickOutside = (isMobile) ? true : false;
			this._showBackdrop = (isMobile) ? true : false;
			this._opened = !isMobile;
			this.sidebarClosed = false;
		});

		this._routerEventsSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd && this.isMobile) {
				this.sidenav.close();
			}
		});


		//Add class on focus of search box in header
		/* document.getElementById('search-field').addEventListener("focusin",function(){
			document.getElementById('search-field').parentElement.classList.add("search-active");
		})

		document.getElementById('search-field').addEventListener("focusout",function(){
			document.getElementById('search-field').parentElement.classList.remove("search-active");
		}) */
	}

  onTimeOut(){

  }

	getUser(){
    this._http.getMethod([],"user").subscribe((data) => {
      console.log('usuario');
      this.id_user = data.WebUser.id;
    }, (err) =>{
      console.log("err",err);
      console.log('Error type order');
    });
  }

  getValidateDhl(){
	this._http.getMethod([], "order/create/classes").subscribe((data) => {
		this.typeOrder = data[0].Name;
		this.getPermissionModuleCheck();
	}, (err) =>{
		console.log("err",err);
		console.log('Error Service');
		this.getPermissionModuleCheck();
	});
  }

	getPermissionModuleCheck(){
		this._http.getMethod([], "web/cheque/permission/module/check").subscribe((data) => {
			this.viewModuleCheck = data;
			console.log('data cheques');
			console.log(data);
			this.getPermissionAdmin();
		}, (err) =>{
			console.log("err",err);
			console.log('Error Service');
			this.getPermissionAdmin();
		});
	}

	getPermissionAdmin(){
		this._http.getMethod([], "user/permission/get/super/admin").subscribe((data) => {
			this.viewIsAdmin = data;
			console.log('data admin');
			console.log(data);
			this.loadPermissionSubModule();
		}, (err) =>{
			console.log("err",err);
			console.log('Error Service');
			this.loadPermissionSubModule();
		});
	}

	loadPermissionSubModule(){
    this._http.getMethod([], "web/permission/permission/module/get").subscribe((data) => {
      // setter
      this.dataPermissionSub = data;
      console.log('permisi xd xd x dxd');
      console.log(this.dataPermissionSub);
			this.getpermissionSubModule();
			this.addMenusValidation();
    }, (err) =>{
      console.log(err);
			this.addMenusValidation();
    });
  }

	getpermissionSubModule(){

    let array1 = this.dataPermissionSub;

		//ORDER
		let found1 = array1.find(element => element == 1);
		if(found1 == 1){
      this.subModuleOrder.push({ state: 'create-order', name: 'Generar Orden' });
		}
		let found2 = array1.find(element => element == 2);
		if(found2 == 2){
			this.subModuleOrder.push({ state: 'status', name: 'Estados de Ordenes' });
		}
		let found3 = array1.find(element => element == 3);
		if(found3 == 3){
			this.subModuleOrder.push({ state: 'destination-status', name: 'Estados de Destinos' });
		}
		let found4 = array1.find(element => element == 4);
		if(found4 == 4){
			this.subModuleOrder.push({ state: 'international-status', name: 'Estados de ordenes internacionales' });
		}
		let found5 = array1.find(element => element == 5);
		if(found5 == 5){
			this.subModuleOrder.push({ state: 'historical', name: 'Historial' });
		}

		//CHECK
		let found6 = array1.find(element => element == 6);
		if(found6 == 6){
			this.subModuleCheck.push({ state: 'checks', name: 'Dashboard' });
		}
		let found7 = array1.find(element => element == 7);
		if(found7 == 7){
			this.subModuleCheck.push({ state: 'manage-checks', name: 'Administrar' });
		}
		let found8 = array1.find(element => element == 8);
		if(found8 == 8){
			this.subModuleCheck.push({ state: 'graph-detail', name: 'Informes' });
		}
		/* this.subModuleOrder = ([
			{ state: 'create-order', name: 'Generar Orden' },
			{ state: 'status', name: 'Estados de Ordenes' },
			{ state: 'destination-status', name: 'Estados de Destinos' },
			{ state: 'international-status', name: 'Estados de ordenes internacionales' },
			{ state: 'historical', name: 'Historial' },
		]) 
		this.subModuleCheck = [
			{ state: 'checks', name: 'Dashboard' },
			{ state: 'manage-checks', name: 'Administrar' },
			{ state: 'graph-detail', name: 'Informes' },
		]
		
		*/
	}

  getBalance(){
    this._http.getMethod([], "user/balance").subscribe((data) => {
      this.balance = data.balanceSaldo;
      this.balanceCantidad = data.balanceCantidad;


    })
  }

	addMenusValidation(){
		let permissions = this.storageService.getPermissions();
		if (permissions.length == 0){
			// Item Menu Cargando...
			this.addMenuItem({
				state: '',
				name: 'Cargando...',
				type: 'ext-link',
				icon: 'icon-refresh icons fa-spin'
			});

			this._http.getMethod([], "user/permissions").subscribe((data) => {
				this.menuItems.clear(); // Limpiar
				permissions = data;
				permissions.forEach(ele => {
					let menuItem = this.setMenuById(ele);
					this.addMenuItem(menuItem);
				});
				//this.addMenuItem(this.setMenuById(9)); // Tags Menu
				this.addMenuItem(this.setMenuById(10)); // Profile Menu
				this.addMenuItem(this.setMenuById(11)); // permisos
				this.addMenuItem(this.setMenuById(12)); // whatsApp
				//this.addMenuItem(this.setMenuById(13)); // log
			}, (err) =>{
				if (err.status == 401) { this.storageService.logout(); }
				else { swal.fire({ icon: 'error', title: 'Ups...', html: '¡Ha ocurrido un error inesperado!<br>'}); }
			});
		}else{
			permissions.forEach(ele => {
				let menuItem = this.setMenuById(ele);
				this.addMenuItem(menuItem);
			});
			//this.addMenuItem(this.setMenuById(9)); // Tags Menu
			this.addMenuItem(this.setMenuById(10)); // Profile Menu
			this.addMenuItem(this.setMenuById(11)); // permisos
			this.addMenuItem(this.setMenuById(12)); // whatsApp
			//this.addMenuItem(this.setMenuById(13)); // log
		}
	}

	setMenuById(id){
		let item: any;
		switch (id) {
			case 1:
					item = {
						state: 'orders',
						name: 'Órdenes',
						type: 'sub',
						icon: 'icon-layers icons',
						children: this.subModuleOrder
				    };
				
			break;
			case 2:
					item = {
						state: 'checks',
						name: 'Cheques',
						type: 'sub',
						icon: 'icon-drawer icons',
						children: this.subModuleCheck
					};
			break;
			case 3:
				item = {
					state: 'cards',
					name: 'Tarjetas',
					type: 'link',
					icon: 'icon-credit-card icons'
				};
			break;/*
			case 9:
				item = {
					state: 'tags',
					name: 'Etiquetas',
					type: 'link',
					label: 'Nuevo',
					icon: 'icon-tag icons'
				};
			break;*/
			case 10:
				item = {
					state: 'user-profile',
					name: 'Mi Perfil',
					type: 'link',
					icon: 'icon-user icons'
				};
			break;

			case 11:
				if(this.viewIsAdmin == 1){
					item = {
						state: 'permission',
						name: 'Permisos',
						type: 'link',
						icon: 'fa fa-cogs',
					};
				}else{
					item = {
					
					};
				}
			break;

			case 12:
				item = {
					state: 'whatsapp-multi',
					name: 'whatsApp',
					type: 'link',
					icon: 'fa fa-whatsapp icono text-success',
				};
			break;

			/* case 13:
				item = {
					state: 'log-error',
					name: 'Log de Errores',
					type: 'link',
					icon: 'fa fa-info icono text-danger',
				};
			break; */
			default:

			break;
		}
		return item;
	}

	testPhase(){
		swal.fire({
			icon: 'info',
			title: 'Multientrega ' + this.versionString,
			html:
				'<b>Multientrega Administrador</b> desarrollado actualmente para ti. '+
				'Gracias por su atención <i class="fa fa-hand-peace-o" aria-hidden="true"></i><br><br>'+
				'<i>Multientrega ' + this.versionString + "</i>.",
			timer: 5000,
			timerProgressBar: true,
			showConfirmButton: false
		});
	}

	ngOnDestroy() {
		this._router.unsubscribe();
		this._mediaSubscription.unsubscribe();
	}

	/**
	  * toggleFullscreen method is used to show a template in fullscreen.
	  */
	toggleFullscreen() {
		if (screenfull.enabled) {
			screenfull.toggle();
			this.isFullscreen = !this.isFullscreen;
		}
	}

	/**
	  * _toggleOpened method is used to toggle a menu list.
	  */
	public _toggleOpened() {
		this._opened = !this._opened;
		this.sidebarClosed = !this.sidebarClosed;
	}

	/**
	  * customizerFunction is used to open and close the customizer.
	  */
	customizerFunction() {
		this.customizerIn = !this.customizerIn;
	}

	/**
	  * chatWindowFunction is used to open and close the chat window.
	  */
	chatWindowFunction() {
		this.chatWindowOpen = !this.chatWindowOpen;
	}

	/**
	  * chatSidebarFunction is used to open and close the chat sidebar list.
	  */
	chatSidebarFunction() {
		this.chatSidebar = !this.chatSidebar;
	}

	/**
	  * changeThemeColor function filter the color for sidebar section.
	  */
	changeThemeColor(color) {
		this.themeSkinColor = color;
	}

	/**
	  * changeHeaderColor function filter the color for header section.
	  */
	changeHeaderColor(color) {
		this.headerSkinColor = color;
	}

	/**
	  * addMenuItem is used to add a new menu into menu list.
	  */
	addMenuItem(data): void {
		this.menuItems.add(data);
	}

	/**
	  * As router outlet will emit an activate event any time a new component is being instantiated.
	  */
	onActivate(e, scrollContainer) {
		scrollContainer.scrollTop = 0;
	}

	/**
	  * changeRTL method is used to change the layout of template.
	  */
	changeRTL(isChecked) {
		if (isChecked) {
			this.layout = "rtl"
			this.coreService.rtlShowStatus = true;
		} else {
			this.layout = "ltr"
			this.coreService.rtlShowStatus = false;
		}
	}

	/**
	  * toggleSidebar method is used to switch between Icon sidebar and Sidebar.
	  */
	toggleSidebar(isChecked) {
		console.log(isChecked)
		this.miniSidebar = !isChecked;
		if (isChecked) {
			document.getElementById('showSidebar').classList.remove('icon-sidebar');
			document.getElementsByClassName('app')[0].classList.remove("icon-sidebar-wrap");
			document.getElementById('boxed-layout').classList.remove('disabled-checkbox');
		}  else {
			document.getElementById('showSidebar').className += " icon-sidebar";
			document.getElementsByClassName('app')[0].className += " icon-sidebar-wrap";
			document.getElementById('boxed-layout').className += " disabled-checkbox";
		}
	}

	/**
	  * logOut method is used to log out the  template.
	  */
	logOut() {
		this.storageService.logout(true);
	}

	/**
	  *closeOnClickOutside method is used when click on outside close/open the sidebar.
	  */
	closeOnClickOutside(){
		if(this._opened){
			this.sidebarClosed = false;
		}
		else{
			this.sidebarClosed =  true;
		}
		return this._closeOnClickOutside;
	}

	/**
	  * boxedLayout method is used to show the box layout.
	  */
	boxedLayout(isChecked){
		if(isChecked){
			this.boxed = true;
			document.getElementById('icon-sidebar').className += " disabled-checkbox";
		}else{
			this.boxed = false;
			document.getElementById('icon-sidebar').classList.remove('disabled-checkbox');
		}
	}
}
